/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, TitleMain, Text, Subtitle, Button, Image, SeparateLine, Title, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1rr1iy0 --style3 bg--bottom --full pb--50 pt--60" name={"introduction"} style={{"backgroundColor":"var(--color-variable-1)"}} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/52/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/52/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/52/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/52/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/52/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/52/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/52/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/52/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --full el--1 flex--bottom" style={{"maxWidth":1200}} columns={"1"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box title-box--style1 ff--1 fs--36 w--600 title-box--invert ls--004 lh--1" content={"Doing things others won't\n"}>
              </TitleMain>

              <Text className="text-box fs--18 w--500 text-box--invert mt--06" style={{"maxWidth":612}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"about-me"} layout={"l13"} lightbox={true}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"maxWidth":340}} content={"<span style=\"color: rgb(0, 0, 0);\">Hi, <span style=\"font-weight: bold;\">my name is&nbsp;Jane Doe</span>&nbsp;and I do things.</span>"}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)","maxWidth":380}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--shape3 btn-box--shadow2 fs--16" content={"Find out more"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/52/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/52/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/52/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/52/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/52/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/52/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--02" name={"divider"} style={{"backgroundColor":"rgba(248, 251, 255, 1)"}}>
        </SeparateLine>


        <Column className="pb--40 pt--40" name={"about-me-2"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"color":"#000"}} content={"A keen interest in&nbsp;<span style=\"font-weight: bold;\">self-development&nbsp;</span>"}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--shape3 btn-box--shadow2 fs--16" href={"#"} content={"Find out more"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"color":"#000"}} content={" 0"}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--shape3 btn-box--shadow2 fs--16" href={"#"} content={"Find out more"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"color":"#000"}} content={"Never afraid to&nbsp;<span style=\"font-weight: bold;\">express myself.</span>"}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--shape3 btn-box--shadow2 fs--16" href={"#"} content={"Find out more"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"color":"#000"}} content={"A&nbsp;<span style=\"font-weight: bold;\">freelance photographer</span> in my free time."}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--shape3 btn-box--shadow2 fs--16" href={"#"} content={"Find out more"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"information"} style={{"backgroundColor":"rgba(248, 251, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--style1">
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"color":"rgb(0, 0, 0)","maxWidth":660}} content={"Click and start writing. This is an area for your text."}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)","maxWidth":660}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-fx9mmw bg--center pb--40 pt--40" name={"information-2"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} css={css`
        
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/52/img-3_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/52/img-3_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/52/img-3_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/52/img-3_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/52/img-3_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/52/img-3_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/52/img-3_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/52/img-3_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":550}}>
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 subtitle-box--invert ls--004 lh--1" style={{"maxWidth":340}} content={"Click and start writing. This is an area for your text."}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"rgb(187, 187, 187)","maxWidth":530}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--shape3 btn-box--shadow2" href={"#"} content={"Find out more"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"information-3"}>
          
          <ColumnWrap className="column__flex el--4 flex--top" style={{"maxWidth":""}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--18 w--600 ls--002" style={{"color":"rgb(0, 0, 0);"}} content={"Click and start writing. This is an area for your text."}>
              </Text>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18 w--600 ls--002" style={{"color":"rgb(0, 0, 0);"}} content={"Click and start writing. This is an area for your text."}>
              </Text>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18 w--600 ls--002" style={{"color":"rgb(0, 0, 0);"}} content={"Click and start writing. This is an area for your text."}>
              </Text>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--18 w--600 ls--002" style={{"color":"rgb(0, 0, 0);"}} content={"Click and start writing. This is an area for your text."}>
              </Text>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--02" name={"divider"} style={{"backgroundColor":"rgba(248, 251, 255, 1)"}}>
        </SeparateLine>


        <Column className="pb--40 pt--40" name={"information-4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":550}}>
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--600 ls--004 lh--1" style={{"color":"rgb(0, 0, 0)","maxWidth":600}} content={"Click and start writing. This is an area for your text."}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"rgb(187, 187, 187)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":990}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"color":"rgb(0, 0, 0)","maxWidth":340}} content={"Click and start writing. This is an area for your text."}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"color":"rgb(0, 0, 0)","maxWidth":340}} content={"Click and start writing. This is an area for your text."}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"color":"rgb(0, 0, 0)","maxWidth":340}} content={"Click and start writing. This is an area for your text."}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--02" name={"divider"} style={{"backgroundColor":"rgba(248, 251, 255, 1)"}}>
        </SeparateLine>


        <Column className="pb--30 pt--30" name={"photogallery"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/52/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/52/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/52/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/52/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/52/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/52/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/52/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/52/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/52/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/52/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/52/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/52/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/52/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/52/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/52/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/52/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/52/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/52/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/52/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/52/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/52/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/52/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/52/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/52/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/52/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/52/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/52/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/52/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/52/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/52/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/52/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/52/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/52/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/52/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/52/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/52/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--02" name={"divider"} style={{"backgroundColor":"rgba(248, 251, 255, 1)"}}>
        </SeparateLine>


        <Column className="pb--40 pt--40" name={"article"}>
          
          <ColumnWrap className="column__flex el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="mt--0">
              
              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"9.12.2019"}>
              </Text>

              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1 mt--02" style={{"color":"rgb(0, 0, 0)","maxWidth":560}} content={"Article: Click and start writing. This is an area for your text."}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --justify el--2 mt--06 flex--top" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--16 ls--002 lh--16" content={"<span style=\"color: rgb(155, 155, 155);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area.</span><br><br><span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.</span><br><br><span style=\"color: rgb(155, 155, 155);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--16 ls--002 lh--16" content={"<span style=\"color: rgb(155, 155, 155);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.</span><br><br><span style=\"font-weight: bold; color: var(--color-variable-1);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"information-5"} style={{"backgroundColor":"rgba(248, 251, 255, 1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" style={{"maxWidth":820}} columns={"2"}>
            
            <ColumnWrapper className="--style1 --right pl--0 pr--0" style={{"maxWidth":450}}>
              
              <Subtitle className="subtitle-box ff--2 fs--26 w--300 ls--004 lh--1" style={{"color":"rgb(0, 0, 0)"}} content={"<span style=\"color: rgb(0, 0, 0);\">Hi, <span style=\"font-weight: bold;\">my name is&nbsp;Jane Doe</span>&nbsp;and I do things.</span>"}>
              </Subtitle>

              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--style1" style={{"maxWidth":450}}>
              
              <Text className="text-box fs--16 ls--002" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized."}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--shape3 btn-box--design2 btn-box--shadow2 fs--18" content={"Find out more"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"contact"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Get in touch"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Telephone number","type":"text","placeholder":"Telephone No."},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"footer"} style={{"backgroundColor":"var(--color-variable-2)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert ls--002" content={"Travel influencer<br><style=\"font-weight: bold;text-transform: uppercase;\">Name Lastname</span>"}>
              </Text>

              <Text className="text-box fs--16 ls--002" content={"<span style=\"color: rgb(187, 187, 187);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized.</span>"}>
              </Text>

              <Text className="text-box fs--16 ls--002" content={"<span style=\"color: rgb(187, 187, 187);\">Created with <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 w--600 text-box--invert ls--002" content={"Contact"}>
              </Text>

              <Text className="text-box fs--16 text-box--invert ls--002" content={"510-851-40X4<br>info@vase-stranky.com"}>
              </Text>

              <Text className="text-box fs--16 ls--002" content={"<span style=\"color: rgb(187, 187, 187);\"><a href=\\https://instagram.com\\\">Instagram.com/yourpage</a>\"\"\"<br><a href=https://facebook.com\">Facebook.com/yourpage</a><br><a href=\\https://youtube.com\\\">Youtube.com/yourpage</a>\"\"\"</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 w--600 text-box--invert ls--002" content={"Navigation"}>
              </Text>

              <Text className="text-box fs--16 ls--002" content={"<span style=\"color: rgb(187, 187, 187);\"><a href=\"#about-me\">About me</a><br><a href=\"#article\">Articles</a><br><a href=\"#photogallery\">Photogallery</a><br><a href=\"#contact\">Contact</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}